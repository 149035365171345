export interface IGroupMembership {
  isGroupMember?: boolean;
  isGroupLeader?: boolean;
  canContact?: boolean;
}

export class GroupMembership implements IGroupMembership {
  isGroupMember?: boolean;
  isGroupLeader?: boolean;
  canContact?: boolean;

  constructor(isGroupMember: boolean = null, isGroupLeader: boolean = null, canContact: boolean = null) {
    this.isGroupMember = isGroupMember;
    this.canContact = canContact;
    this.isGroupLeader = isGroupLeader;
  }
}
