import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import {
  Angulartics2GoogleTagManager,
  Angulartics2Segment
} from "angulartics2";
import { AuthService } from "./services/auth.service";
import { ContentService } from "./services/content.service";
import { PreLoaderService } from "./components/preloader/preloader.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  showHeader = false;
  ready = false;

  constructor(
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private angulartics2Segment: Angulartics2Segment,
    private router: Router,
    private auth: AuthService,
    private content: ContentService,
    private preloader: PreLoaderService
  ) {}

  ngOnInit(): void {
    this.showHeader = window.location.pathname.toUpperCase().includes("FAQ");
    this.auth.anonymousLogin().then(() => {
      this.scrollToTop();
      this.content
        .getContent("HomepageJumbotronText")
        .subscribe(contentloaded => {
          this.ready = true;
          this.preloader.hideLoader();
        });
    });
  }

  private scrollToTop() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeader = true;
        window.scrollTo(0, 0);
        if (this.router.url === "/") {
          this.showHeader = false;
        }
      }
    });
  }
}
