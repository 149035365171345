import { Injectable } from "@angular/core";

@Injectable()
export class PreLoaderService {
  public show = true;

  showLoader() {
    this.show = true;
  }

  hideLoader() {
    this.show = false;
  }
}
