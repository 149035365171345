import { StateService } from "../../services/state.service";
import { AssessmentService } from "../../services/assessment.service";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import {
  STOP_AND_BREATHE_ONE,
  STOP_AND_BREATHE_TWO
} from "../../shared/constants";

@Component({
  selector: "app-stop-and-breathe",
  templateUrl: "./stop-and-breathe.component.html",
  styleUrls: ["./stop-and-breathe.component.scss"]
})
export class StopAndBreatheComponent implements OnInit {
  public breath = STOP_AND_BREATHE_ONE;
  public startPropertyName = "stopAndBreathe1StartDate";
  public completePropertyName = "stopAndBreathe1CompleteDate";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private assessmentService: AssessmentService,
    private stateService: StateService
  ) {}

  ngOnInit() {
    if (this.route.snapshot.queryParams["breathe"] === "exhale") {
      this.breath = STOP_AND_BREATHE_TWO;
      this.startPropertyName = "stopAndBreathe2StartDate";
      this.completePropertyName = "stopAndBreathe2CompleteDate";
      this.stateService.SetBreath2Taken();
    } else {
      // inhale
      this.stateService.SetBreath1Taken();
    }
    this.startStopAndBreathe();
  }

  public moreQuestions() {
    this.endStopAndBreathe();
    const navigationExtras: NavigationExtras = {
      queryParams: { forward: this.breath }
    };
    this.router.navigate(["questions"], navigationExtras);
  }

  private startStopAndBreathe() {
    this.assessmentService.UpdateAssessment(
      this.assessmentService.GetAssessmentId(),
      this.startPropertyName,
      new Date()
    );
  }

  private endStopAndBreathe() {
    this.assessmentService.UpdateAssessment(
      this.assessmentService.GetAssessmentId(),
      this.completePropertyName,
      new Date()
    );
  }
}
