import { Injectable } from "@angular/core";
import { UrlSegment } from "@angular/router";

@Injectable()
export class StateService {
  constructor() { }

  private appJustLoaded = true;
  private debugMode = null;
  private openForBusiness = true;
  private breath1Taken = false;
  private breath2Taken = false;

  public GetFinalPage(): string {
    return "thankyou";
  }

  public GetAppJustLoaded() {
    return this.appJustLoaded;
  }

  public SetAppLoaded() {
    this.appJustLoaded = false;
  }

  public SetBreath1Taken() {
    this.breath1Taken = true;
  }

  public GetBreath1Taken() {
    return this.breath1Taken;
  }

  public SetBreath2Taken() {
    this.breath2Taken = true;
  }

  public GetBreath2Taken() {
    return this.breath2Taken;
  }

  public SetDebugMode(value: boolean) {
    const url = this.getOrigin();
    if (this.debugMode == null) {
      this.debugMode =
        url.startsWith("int") || url.startsWith("localhost") ? value : false;
    }
  }

  public GetDebugMode(): boolean {
    return this.debugMode;
  }

  public SetOpenForBusiness(value: boolean) {
    this.openForBusiness = value;
  }

  public GetOpenForBusiness(): boolean {
    return this.openForBusiness;
  }

  private getOrigin(): string {
    // Regex here gets rid of http:// or https://
    return window.location.origin.replace(/(^\w+:|^)\/\//, "");
  }
}
