import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  ElementRef,
  QueryList
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  group,
  keyframes
} from "@angular/animations";
import { Answer } from "../../models/answer";
import { Assessment } from "../../models/assessment";
import { WfResponse } from "../../models/response";

@Component({
  selector: "app-question",
  templateUrl: "./question.component.html",
  animations: [
    trigger("questionState", [
      transition(
        "* => active",
        animate(
          "350ms ease-in",
          keyframes([
            style({ opacity: 0, transform: "translateX(20px)", offset: 0 }),
            style({ opacity: 1, transform: "translateX(0)", offset: 1.0 })
          ])
        )
      ),
      transition(
        "* => inactive",
        animate(
          "250ms ease-out",
          style({
            opacity: 0
          })
        )
      )
    ])
  ]
})
export class QuestionComponent implements OnInit {
  public selectedAnswerId;
  public questionForm: FormGroup;
  public state: string;

  constructor() {}

  @Input() response: WfResponse;
  @Input() answers: Answer[];
  @Output() questionAnswered: EventEmitter<WfResponse> = new EventEmitter();
  @Output() questionReady: EventEmitter<Boolean> = new EventEmitter();
  // this is part of a hack around this issue:
  // https://github.com/angular/angular/issues/24084
  public alreadyFinished = false;

  @ViewChildren("options") answerOptionElements: QueryList<ElementRef>;

  ngOnInit() {
    this.state = "active";
    this.questionForm = new FormGroup({
      question: new FormControl()
    });
  }

  public answerSelected() {
    // this is part of a hack around this issue:
    // https://github.com/angular/angular/issues/24084
    this.alreadyFinished = false;
    const selectedAnswer: Answer = this.answers.find(a => {
      return a.documentid === this.selectedAnswerId;
    });

    this.response.answertext = selectedAnswer.answertext;
    this.response.answervalue = selectedAnswer.points;

    this.questionAnswered.emit(this.response);
    this.resetForm();
  }

  afterAnimation(e) {
    if (e.toState === "inactive") {
      // this is part of a hack around this issue:
      // https://github.com/angular/angular/issues/24084
      if (!this.alreadyFinished) {
        this.questionReady.emit(true);
        this.state = "active";
        this.alreadyFinished = true;
      }
    }
  }

  private resetForm() {
    this.questionForm.reset();
    this.state = "inactive";
    this.answerOptionElements.toArray().forEach(el => {
      el.nativeElement.blur();
    });
  }
}
