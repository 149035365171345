import { Question } from "./question";

export interface IWfResponse {
  documentid: string;
  questiontext: string;
  questionweight: number;
  category: string;
  categoryweight: number;
  answertext: string;
  answervalue: number;
  startdate: Date;
  enddate: Date;
  sortorder: number;
  questionid: string;
}

export class WfResponse implements IWfResponse {
  documentid: string;
  questiontext: string;
  questionweight: number;
  category: string;
  categoryweight: number;
  answertext: string;
  answervalue: number;
  startdate: Date;
  enddate: Date;
  sortorder: number;
  questionid: string;

  constructor(question: Question, sortorder: number = null) {
    this.questiontext = question.questiontext;
    this.questionweight = question.questionweight;
    this.category = question.category;
    this.categoryweight = question.categoryweight;
    this.documentid = question.documentid;
    this.sortorder = sortorder;
    this.questionid = question.questionid;
  }
}
