import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Content } from "../../models/content";
import { ContentService } from "../../services/content.service";
import { percentageToUpdateProgressBar } from "../../shared/constants";

@Component({
  selector: "app-question-progress-bar",
  templateUrl: "./question-progress-bar.component.html"
})
export class QuestionProgressBarComponent implements OnInit, OnChanges {
  @Input() max: number;
  @Input() answered: number;
  public encouragement: Content;
  public ready = false;
  private shownAnswered: number = null;
  private encouragements: Content[];
  private modulus: number;
  constructor(public contentService: ContentService) { }

  ngOnInit() {
    this.modulus = this.max * percentageToUpdateProgressBar;
    this.encouragements = this.contentService.getEncouragements();
    this.updateNumberOfQuestionsAnswered();
    this.ready = true;
  }

  ngOnChanges() {
    // Happens before NgOnInit
    if (this.ready) {
      this.updateNumberOfQuestionsAnswered();
    }
  }

  public GetPercentage() {
    if (this.shownAnswered === 0) {
      return 0 + "%";
    } else if (this.shownAnswered === this.max) {
      return 99 + "%";
    } else {
      return this.shownAnswered / this.max * 100 + "%";
    }
  }

  private updateNumberOfQuestionsAnswered() {
    // if we havent set questions answered or if we've answered the correct number of questions
    // update totalQuestionsAnswered and update the progress bar.
    if (this.shownAnswered == null || this.answered % this.modulus === 0) {
      this.shownAnswered = Math.floor(this.answered / 10) * 10; // round down to nearest 10
      const index = this.shownAnswered / 10 < 0 ? 0 : this.shownAnswered / 10;
      if (index < this.encouragements.length - 1) {
        this.encouragement = this.encouragements[index];
      } else {
        this.encouragement = this.encouragements.slice(-1)[0];
      }
    }
  }
}
