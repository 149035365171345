export class Score {
  financial: number;
  spiritual: number;
  relational: number;
  physical: number;
  intellectual: number;

  constructor() {
    this.financial = 0;
    this.spiritual = 0;
    this.relational = 0;
    this.physical = 0;
    this.intellectual = 0;
  }
}
