import { Component } from "@angular/core";
import { PreLoaderService } from "./preloader.service";

@Component({
  selector: "app-preloader",
  templateUrl: "./preloader.component.html",
  styleUrls: ["./preloader.component.scss"]
})
export class PreLoaderComponent {
  constructor(public preloaderService: PreLoaderService) { }
}
