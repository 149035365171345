import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs/Observable";

import { CookieService } from "ngx-cookie";
import { DataService } from "../services/data.service";
import { AssessmentService } from "../services/assessment.service";
import { ContentService } from "../services/content.service";
import { Assessment } from "../models/assessment";
import { PreLoaderService } from "../components/preloader/preloader.service";
import { StateService } from "../services/state.service";
@Injectable()
export class ResumeOldAssessmentGuard implements CanActivate {
  constructor(
    private cookieService: CookieService,
    private dataService: DataService,
    private assessmentService: AssessmentService,
    private router: Router,
    private state: StateService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    next.queryParamMap.get("debug")
      ? this.state.SetDebugMode(true)
      : this.state.SetDebugMode(false);
    if (this.state.GetAppJustLoaded()) {
      this.state.SetAppLoaded();
      return this.dataService.GetConfig().flatMap(config => {
        if (this.state.GetOpenForBusiness() !== true && this.state.GetDebugMode() === false) {
          return Observable.of(true);
        }
        const assessmentId = next.queryParamMap.get("AssessmentID")
          ? next.queryParamMap.get("AssessmentID")
          : this.cookieService.get("AssessmentID");
        const version = this.cookieService.get("Version");
        console.log(assessmentId);
        if (
          assessmentId &&
          (version == config.currentversion ||
            this.state.GetDebugMode() === true)
        ) {
          return this.assessmentService
            .GetAssessmentByID(assessmentId)
            .flatMap(assessment => this.resumeAssessment(assessment))
            .catch(err => {
              console.log(
                `Assessment with Document ID: '${assessmentId}' not found`
              );
              return this.clearOldAssessment();
            });
        } else {
          return this.clearOldAssessment();
        }
      });
    }
    {
      return Observable.of(true);
    }
  }

  private resumeAssessment(assessment: Assessment): Observable<boolean> {
    this.router.navigate(["/resume"]);
    return Observable.of(true);
  }

  private clearOldAssessment(): Observable<boolean> {
    this.cookieService.removeAll();
    this.assessmentService.ClearAssessment();
    this.router.navigate([""]);
    return Observable.of(false);
  }
}
