import { Component } from "@angular/core";
import { Router } from "@angular/router";
import "rxjs/add/operator/map";
import { StateService } from "../../services/state.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html"
})
export class HomeComponent {
  constructor(private router: Router, public state: StateService) {}
  public takeAssesment() {
    this.router.navigate(["setup/1"]);
  }
  public gotoFAQ() {
    this.router.navigate(["faq"]);
  }
}
