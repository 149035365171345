import { OpenResponseComponent } from "./components/open-response/open-response.component";
import { StopAndBreatheComponent } from "./components/stop-and-breathe/stop-and-breathe.component";
import { FinalquestionPageComponent } from "./components/finalquestion-page/finalquestion-page.component";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./components/home/home.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ResultsComponent } from "./components/results/results.component";
import { InfoPageComponent } from "./components/info-page/info-page.component";
import { InstructionsComponent } from "./components/instructions/instructions.component";
import { QuestionFormComponent } from "./components/question-form/question-form.component";
import { ResumeOldAssessmentGuard } from "./route-guards/resume-old-assessment.guard";
import { ResultsOverviewComponent } from "./components/results-overview/results-overview.component";
import { ResultsOverviewDetailComponent } from "./components/results-overview-detail/results-overview-detail.component";
import { ResumeConfirmationComponent } from "./components/resume-confirmation/resume-confirmation.component";
import { ThankyouComponent } from "./components/thankyou/thankyou.component";
import { QuestionGuard } from "./route-guards/question.guard";
import { OpenForBusinessGuard } from "./route-guards/openforbusiness.guard";
import { OpenResponseGuard } from "./route-guards/openresponse.guard";
import { FAQComponent } from "./components/faq/faq.component";
import { StopAndBreatheGuard } from "./route-guards/stopbreathe.guard";

const routes: Routes = [
  {
    path: "faq",
    component: FAQComponent
  },
  {
    path: "",
    canActivate: [OpenForBusinessGuard, ResumeOldAssessmentGuard],
    children: [
      {
        path: "",
        component: HomeComponent
      },
      {
        path: "results",
        children: [
          {
            path: "",
            component: ResultsComponent
          },
          {
            path: "overview-of-results",
            component: ResultsOverviewComponent,
            children: [
              {
                path: "seeking",
                component: ResultsOverviewDetailComponent
              },
              {
                path: "receiving",
                component: ResultsOverviewDetailComponent
              },
              {
                path: "following",
                component: ResultsOverviewDetailComponent
              },
              {
                path: "producing",
                component: ResultsOverviewDetailComponent
              }
            ]
          }
        ]
      },
      {
        path: "info",
        component: InfoPageComponent
      },
      {
        path: "setup/1",
        component: InstructionsComponent
      },
      {
        path: "setup/2",
        component: InstructionsComponent
      },
      {
        path: "setup/3",
        component: InstructionsComponent
      },
      {
        path: "setup/4",
        component: InstructionsComponent
      },
      {
        path: "setup/5",
        component: InstructionsComponent
      },
      {
        path: "setup/6",
        component: InstructionsComponent
      },
      {
        path: "wrapup",
        component: FinalquestionPageComponent,
        canDeactivate: [QuestionGuard]
      },
      {
        path: "questions",
        component: QuestionFormComponent,
        canDeactivate: [QuestionGuard]
      },
      {
        path: "stopandbreathe",
        component: StopAndBreatheComponent,
        canDeactivate: [StopAndBreatheGuard]
      },
      {
        path: "resume",
        component: ResumeConfirmationComponent
      },
      {
        path: "thankyou",
        component: ThankyouComponent,
        canDeactivate: [QuestionGuard]
      },
      {
        path: "openresponse",
        children: [
          {
            path: "intro",
            component: OpenResponseComponent,
            canDeactivate: [OpenResponseGuard]
          },
          {
            path: "1",
            component: OpenResponseComponent,
            canDeactivate: [OpenResponseGuard]
          },
          {
            path: "2",
            component: OpenResponseComponent,
            canDeactivate: [OpenResponseGuard]
          },
          {
            path: "3",
            component: OpenResponseComponent,
            canDeactivate: [OpenResponseGuard]
          },
          {
            path: "4",
            component: OpenResponseComponent,
            canDeactivate: [OpenResponseGuard]
          },
          {
            path: "ideas",
            component: OpenResponseComponent,
            canDeactivate: [OpenResponseGuard]
          },
          {
            path: "next",
            component: OpenResponseComponent,
            canDeactivate: [OpenResponseGuard]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
