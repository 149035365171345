import { CRDSSite } from "./crds.site";

export interface IInfoFormData {
  email: string;
  firstName: string;
  lastName: string;
  church: string;
  isGroupMember: boolean;
  isGroupLeader: boolean;
  canContact: boolean;
  site: CRDSSite;
  otherChurchDetail: string;
}

export class InfoFormData implements IInfoFormData {
  email: string;
  firstName: string;
  lastName: string;
  church: string;
  isGroupMember: boolean;
  isGroupLeader: boolean;
  canContact: boolean;
  site: CRDSSite;
  otherChurchDetail: string;
}
