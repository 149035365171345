import { Injectable } from "@angular/core";
import { Angulartics2 } from "angulartics2";
import { AssessmentService } from "./assessment.service";

@Injectable()
export class AnalyticsService {
  constructor(
    private analytics: Angulartics2,
    private assessmentService: AssessmentService
  ) {}

  private assessmentDocumentID = null;

  public trackWayfinderAssessmentStarted(assessmentID: string) {
    this.analytics.eventTrack.next({
      action: `WayfinderAssessmentStarted`,
      properties: {
        AssessmentID: assessmentID
      }
    });
  }

  public trackWayfinderAssessmentCompleted(assessmentID: string) {
    this.analytics.eventTrack.next({
      action: `WayfinderAssessmentCompleted`,
      properties: {
        AssessmentID: assessmentID
      }
    });
  }

  public trackWayfinderResourceClicked(
    assessmentID: string,
    linkName: string,
    url: string
  ) {
    this.analytics.eventTrack.next({
      action: `WayfinderResourceClicked`,
      properties: {
        AssessmentID: assessmentID,
        URL: url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""),
        LinkName: linkName
      }
    });
  }

  public trackWayfinderQuestionStatus(
    questionIsStarted: boolean,
    questionID: string,
    category: string
  ) {
    if (this.assessmentDocumentID) {
      questionIsStarted
        ? this.trackWayfinderQuestionStarted(
            this.assessmentDocumentID,
            questionID,
            category
          )
        : this.trackWayfinderQuestionCompleted(
            this.assessmentDocumentID,
            questionID,
            category
          );
    } else {
      this.assessmentService.GetAssessment().subscribe(assessment => {
        this.assessmentDocumentID = assessment.documentid;
        questionIsStarted
          ? this.trackWayfinderQuestionStarted(
              this.assessmentDocumentID,
              questionID,
              category
            )
          : this.trackWayfinderQuestionCompleted(
              this.assessmentDocumentID,
              questionID,
              category
            );
      });
    }
  }

  public trackWayfinderTooltipClicked(
    questionID: string,
    tooltipClicked: string,
    action: string
  ) {
    if (this.assessmentDocumentID) {
      this.trackTooltipClicked(
        this.assessmentDocumentID,
        questionID,
        tooltipClicked,
        action
      );
    } else {
      this.assessmentService.GetAssessment().subscribe(assessment => {
        this.assessmentDocumentID = assessment.documentid;
        this.trackTooltipClicked(
          this.assessmentDocumentID,
          questionID,
          tooltipClicked,
          action
        );
      });
    }
  }

  private trackWayfinderQuestionStarted(
    assessmentID: string,
    questionID: string,
    category: string
  ) {
    this.analytics.eventTrack.next({
      action: `WayfinderQuestionStarted`,
      properties: {
        AssessmentID: assessmentID,
        QuestionID: questionID,
        Category: category
      }
    });
  }

  private trackWayfinderQuestionCompleted(
    assessmentID: string,
    questionID: string,
    category: string
  ) {
    this.analytics.eventTrack.next({
      action: `WayfinderQuestionCompleted`,
      properties: {
        AssessmentID: assessmentID,
        QuestionID: questionID,
        Category: category
      }
    });
  }

  private trackTooltipClicked(
    assessmentID: string,
    questionID: string,
    tooltipClicked: string,
    action: string
  ) {
    this.analytics.eventTrack.next({
      action: `WayfinderTooltipClicked`,
      properties: {
        AssessmentID: assessmentID,
        QuestionID: questionID,
        TooltipClicked: tooltipClicked,
        Action: action
      }
    });
  }

  public trackWayfinderBGMusicPlayed(assessmentID: string) {
    this.analytics.eventTrack.next({
      action: `WayfinderBGMusicPlayed`,
      properties: {
        AssessmentID: assessmentID,
      }
    });
  }

  public trackWayfinderBGMusicPaused(assessmentID: string) {
    this.analytics.eventTrack.next({
      action: `WayfinderBGMusicPaused`,
      properties: {
        AssessmentID: assessmentID
      }
    });
  }
}
