import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";
import { AngularFireAuth } from "angularfire2/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument
} from "angularfire2/firestore";

interface IUser {
  uid: string;
  email?: string | null;
  firstName?: string;
  lastName?: string;
}

@Injectable()
export class AuthService {
  private uid: string;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore) { }

  anonymousLogin() {
    return this.afAuth.auth
      .signInAnonymously()
      .then(user => {
        console.log("anonymous signin successful");
        console.log(user);
        this.uid = user.user.uid;
        this.afAuth.auth.onAuthStateChanged(loggedInuUser => {
          if (loggedInuUser === null) {
            return this.anonymousLogin();
          }
        });
      })
      .catch(error => {
        console.error(error.code);
        console.error(error.message);
      });
  }

  public getUid() {
    return this.uid;
  }
}
