import { Component, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { Answer } from "../../models/answer";
import { WfResponse } from "../../models/response";
import { AnalyticsService } from "../../services/analytics.service";
import { AnswersService } from "../../services/answer.service";
import { AssessmentService } from "../../services/assessment.service";
import { StateService } from "../../services/state.service";

@Component({
  selector: "app-question-form",
  templateUrl: "./question-form.component.html"
})
export class QuestionFormComponent implements OnInit {
  public answers: Answer[];
  public response: WfResponse;
  public totalQuestions: number;
  public ready = false;
  private assessmentDocumentID = null;
  public responses: WfResponse[];

  constructor(
    private assessmentService: AssessmentService,
    private answerService: AnswersService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.answerService.GetAnswers().subscribe(answersArray => {
      this.responses = this.assessmentService.GetRequiredResponses().reverse();
      this.totalQuestions = this.assessmentService.GetTotalQuestions();
      this.answers = answersArray.sort((a, b) => {
        return a.sequence - b.sequence;
      });
      this.prepNextQuestion();
      this.ready = true;
    });
  }

  public questionAnswered(response: WfResponse) {
    this.analyticsService.trackWayfinderQuestionStatus(
      false,
      response.questionid,
      response.category
    );
    response.enddate = new Date();
    this.assessmentService.SaveResponse(response);
  }

  public questionReady() {
    if (this.responses.length >= 1) {
      this.prepNextQuestion();
    } else {
      this.router.navigate(["openresponse/intro"]);
    }
  }

  private prepNextQuestion(): void {
    console.log(
      `questions answered -->${this.totalQuestions - this.responses.length}`
    );

    if (
      this.totalQuestions - this.responses.length === 33 &&
      !this.stateService.GetBreath1Taken()
    ) {
      const navigationExtras: NavigationExtras = {
        queryParams: { breathe: "inhale" }
      };
      this.router.navigate(["stopandbreathe"], navigationExtras);
    }

    if (
      this.totalQuestions - this.responses.length === 66 &&
      !this.stateService.GetBreath2Taken()
    ) {
      const navigationExtras: NavigationExtras = {
        queryParams: { breathe: "exhale" }
      };
      this.router.navigate(["stopandbreathe"], navigationExtras);
    }

    if (this.responses.length > 0) {
      this.response = this.responses.pop();
      this.response.startdate = new Date();
      this.analyticsService.trackWayfinderQuestionStatus(
        true,
        this.response.questionid,
        this.response.category
      );
    }
  }
}
