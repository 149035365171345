import { StateService } from "../services/state.service";
import { Injectable } from "@angular/core";
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { QuestionFormComponent } from "../components/question-form/question-form.component";
import { Observable } from "rxjs/Observable";
import { AssessmentService } from "../services/assessment.service";
import { PlatformLocation } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { ContentService } from "../services/content.service";

@Injectable()
export class QuestionGuard implements CanDeactivate<QuestionFormComponent> {
  constructor(
    private assessmentService: AssessmentService,
    private toast: ToastrService,
    private location: PlatformLocation,
    private content: ContentService,
    private stateService: StateService
  ) {}

  canDeactivate(
    component: QuestionFormComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    switch (currentRoute.url[0].toString()) {
      case "questions":
        if (
          nextState.url.startsWith("/stopandbreathe?breathe=inhale") &&
          this.stateService.GetBreath1Taken() === false
        ) {
          return true;
        } else if (
          nextState.url.startsWith("/stopandbreathe?breathe=exhale") &&
          this.stateService.GetBreath2Taken() === false
        ) {
          return true;
        } else {
          const responses = this.assessmentService.GetRequiredResponses();
          const questionsFinished = responses.length === 0;
          if (!questionsFinished) {
            this.showToastError();
            this.location.pushState(null, null, "/questions");
          }
          return questionsFinished;
        }
      case "wrapup":
        return this.assessmentService.GetAssessment().map(assessment => {
          const finished = assessment.completedDate != null;
          if (!finished) {
            this.showToastError();
            this.location.pushState(null, null, "/wrapup");
          }
          return finished;
        });
      default:
        this.showToastError();
        return false;
    }
  }

  private showToastError() {
    this.content.getContent("wfBackError").subscribe(block => {
      const text = block
        ? block.content
        : "Sorry, you may not go back once you've started";
      this.toast.error(text, "", {
        timeOut: 3000
      });
    });
  }
}
