import * as moment from "moment";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from "angularfire2/firestore";
import { firestore } from "firebase";
import { Answer } from "../models/answer";
import { Assessment } from "../models/assessment";
import { Config } from "../models/config";
import { Content } from "../models/content";
import { CookieService, CookieOptions } from "ngx-cookie";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { Question } from "../models/question";
import { WfResponse } from "../models/response";
import "rxjs/add/observable/of";
import "rxjs/add/operator/do";
import "rxjs/add/operator/map";
import "rxjs/add/operator/mergeAll";
import "rxjs/add/observable/fromPromise";
import "rxjs/add/operator/retry";
import { mergeMap } from "rxjs/operator/mergeMap";
import { Log } from "../models/log";
import { AuthService } from "./auth.service";
import { StateService } from "./state.service";

@Injectable()
export class DataService {
  answersCol: AngularFirestoreCollection<Answer>;
  answers: Observable<Answer[]>;

  questionsCol: AngularFirestoreCollection<Question>;
  questions: Observable<Question[]>;

  assessmentCol: AngularFirestoreCollection<Assessment>;
  assessment: Observable<Assessment>;

  config: Config = null;

  private versionString = "";
  private cookieExpirationLengthInHours = 2;

  constructor(
    private documentDB: AngularFirestore,
    private cookieService: CookieService,
    private authService: AuthService,
    private state: StateService
  ) { }

  public AddOrUpdateAssessmentWithResponsesAsSubCollection(
    assessment: Assessment
  ): Observable<Assessment> {
    const assessmentDocumentid =
      assessment.documentid !== undefined
        ? assessment.documentid
        : this.documentDB.createId();
    assessment.documentid = assessmentDocumentid;
    const jsonObject = this.prepareDocForAFS(assessment);
    const batch = this.documentDB.firestore.batch();
    const documentRef = this.documentDB.firestore
      .collection("Assessments")
      .doc(assessmentDocumentid);

    batch.set(documentRef, jsonObject);

    documentRef.set(jsonObject).catch(error => {
      console.error("Error adding document: ", error);
    });

    // now deal with our list of responses if there are any
    if (assessment.responses) {
      this.saveAssessmentResponses(assessment.responses, documentRef, batch);
    }

    this.saveDataToCookie(assessmentDocumentid);
    console.log(assessmentDocumentid);
    return Observable.fromPromise(batch.commit()).map(x => {
      return assessment;
    });
  }

  private saveDataToCookie(assessmentID) {
    const expiration = moment()
      .add(this.cookieExpirationLengthInHours, "h")
      .toDate();

    const cookieOptions: CookieOptions = {};
    cookieOptions.expires = expiration;

    this.cookieService.put("AssessmentID", assessmentID, cookieOptions);
    this.cookieService.put("Version", this.GetCurrentVersion(), cookieOptions);
  }

  private saveAssessmentResponses(
    responses: WfResponse[],
    documentRef: any,
    batch: any
  ) {
    for (const response of responses) {
      // add to Response collection of above document
      const responseJsonObject = this.prepareDocForAFS(response);
      const responseDoc = documentRef
        .collection("Responses")
        .doc(
          response.documentid !== undefined
            ? response.documentid
            : this.documentDB.createId()
        );
      batch.set(responseDoc, responseJsonObject);
    }
  }

  public log(log: Log) {
    this.documentDB.firestore
      .collection("Logger")
      .doc(this.documentDB.createId())
      .set(this.prepareDocForAFS(log));
  }

  public GetAssessmentByID(documentid: string): Observable<Assessment> {
    const debugmode = this.state.GetDebugMode()
    const userUid = debugmode === true ? "debug" : this.authService.getUid();
    console.log("userid = " + userUid);
    return (
      this.documentDB
        // get the assessment
        .collection("Assessments", ref => ref.where("uid", "==", userUid))
        .doc(documentid)
        .snapshotChanges()
        .map(a => {
          // append the documentID from in the firestore to the assessment object
          const assessment = a.payload.data() as Assessment;
          assessment.documentid = a.payload.id;
          return assessment;
        })
        // get the responses for the assessment -- its dumb that we can't just do this by asking for the assessment
        .flatMap(assessment => this.mapResponsesToAssessment(assessment))
    );
  }

  private mapResponsesToAssessment(assessment): Observable<Assessment> {
    return (
      this.documentDB
        .collection("Assessments")
        .doc(assessment.documentid)
        .collection("Responses")
        .snapshotChanges()
        // append the documentid to each response
        .map(actions => {
          return actions.map(a => {
            const response = a.payload.doc.data() as WfResponse;
            response.documentid = a.payload.doc.id;
            return response;
          });
        })
        // append the responses to the originial assessment object
        .map(responses => {
          responses.sort(this.sortBySortOrder);
          assessment.responses = responses;
          return assessment;
        })
    );
  }

  private sortBySortOrder(a: WfResponse, b: WfResponse): number {
    /* tslint:disable-next-line:curly */
    if (a.sortorder === b.sortorder) return 0;
    if (a.sortorder > b.sortorder) {
      return 1;
    } else {
      return -1;
    }
  }

  public GetAnswers(versionString: string): Observable<Answer[]> {
    this.answersCol = this.documentDB.collection("Answers", ref =>
      ref.where("version", "==", this.versionString)
    );
    return (this.answers = this.answersCol.snapshotChanges().map(actions => {
      return actions.map(a => {
        const answer = a.payload.doc.data() as Answer;
        answer.documentid = a.payload.doc.id;
        return answer;
      });
    }));
  }

  public GetQuestions(): Observable<Question[]> {
    this.questionsCol = this.documentDB.collection("Questions", ref =>
      ref.where("version", "==", this.versionString)
    );
    return (this.questions = this.questionsCol
      .snapshotChanges()
      .retry(3)
      .map(actions => {
        console.log(`Loaded ${actions.length} questions`);
        if (actions.length === 0) {
          throw new Error("Firebase didn't give us questions");
        }
        return actions.map(a => {
          const question = a.payload.doc.data() as Question;
          question.documentid = a.payload.doc.id;
          return question;
        });
      }).retry(3));
  }

  public GetConfig(): Observable<Config> {
    if (this.config != null) {
      return Observable.of(this.config);
    } else {
      return this.documentDB
        .collection("Configs")
        .valueChanges()
        .do(c => {
          if (c.length > 0 && c != null) {
            this.config = c[0] as Config;
            this.SetCurrentVersion(this.config.currentversion);
          }
        })
        .map(c => {
          if (c.length === 0) {
            throw new Error("could not get config");
          } else {
            return c[0] as Config;
          }
        }).retry(5);
    }
  }

  public GetContentBlocks(): Observable<Content[]> {
    return this.documentDB
      .collection("ContentBlocks")
      .valueChanges()
      .map(res => {
        return res as Content[];
      });
  }

  public UpdateAssessment(assessmentid: string, updateObj: object) {
    this.documentDB
      .collection("Assessments")
      .doc(assessmentid)
      .update(updateObj)
      .then(() => console.log(`Updated: ${assessmentid} successfully`))
      .catch(err => console.log(err));
  }

  public SaveResponse(response: WfResponse, assessmentid: string) {
    this.documentDB
      .collection("Assessments")
      .doc(assessmentid)
      .collection("Responses")
      .doc(response.documentid)
      .set(this.prepareDocForAFS(response));
  }

  public SetCurrentVersion(value: string) {
    this.versionString = value;
    console.log("Wayfinder Version = " + value);
  }

  public GetCurrentVersion(): string {
    return this.versionString;
  }

  private prepareDocForAFS(doc: any) {
    const temp = JSON.parse(JSON.stringify(doc));
    delete temp.documentid;
    delete temp.responses;

    return temp;
  }
}
