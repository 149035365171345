import { WfResponse } from "./response";
import { GroupMembership } from "./groupMembership";

export interface IAssessment {
  documentid: string;
  email: string;
  firstname: string;
  lastname: string;
  congregation: string;
  congregationid: number;
  userid: string;
  version: string;
  assessmentdate: Date;
  learned: string;
  personname: string;
  startDate: Date;
  completedDate: Date;
  uid: string;
  reflectionEmailSent: boolean;
  resultsEmailSent: boolean;
  openResponse1: string;
  openResponse2: string;
  openResponse3: string;
  openResponse4: string;
}

export class Assessment implements IAssessment {
  documentid: string;
  email: string;
  firstname: string;
  lastname: string;
  congregation: string;
  congregationid: number;
  groupMembership: GroupMembership;
  userid: string;
  version: string;
  assessmentdate: Date;
  learned: string;
  personname: string;
  responses: WfResponse[];
  startDate: Date;
  completedDate: Date;
  uid: string;
  reflectionEmailSent: boolean;
  resultsEmailSent: boolean;
  openResponse1: string;
  openResponse2: string;
  openResponse3: string;
  openResponse4: string;
}
