import { Answer } from "../models/answer";
import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { Observable } from "rxjs/Observable";
@Injectable()
export class AnswersService {
  private answers: Answer[];
  private currentVersion: string = "1.0.0";

  constructor(private dataService: DataService) {}

  public GetAnswers(): Observable<Answer[]> {
    if (this.answers == null) {
      return this.dataService.GetAnswers(this.currentVersion).do(answers => {
        this.answers = answers;
      });
    } else {
      console.log("GetAssessment in service");
      return Observable.of(this.answers);
    }
  }
}
