export class QuestionCategory {
  SPIRITUAL: string;
  FINANCIAL: string;
  PHYSICAL: string;
  INTELLECTUAL: string;
  RELATIONAL: string;

  constructor() {
    this.SPIRITUAL = "Spiritual";
    this.FINANCIAL = "Financial";
    this.PHYSICAL = "Physical";
    this.INTELLECTUAL = "Intellectual";
    this.RELATIONAL = "Relational";
  }
}
export const questionCategory = new QuestionCategory();
export const percentageToUpdateProgressBar = 0.1; // Percentage of questions answered to update progress bar
export const encouragementPrefix = "encouragementText"; // Encouragement Content block prefix.

// tslint:disable-next-line:max-classes-per-file
export class CategoryBoundries {
  SEEKING_LOW: number;
  SEEKING_HIGH: number;
  RECEIVING_LOW: number;
  RECEIVING_HIGH: number;
  FOLLOWING_LOW: number;
  FOLLOWING_HIGH: number;
  PRODUCING_LOW: number;
  PRODUCING_HIGH: number;

  constructor() {
    this.SEEKING_LOW = 80;
    this.SEEKING_HIGH = 154;
    this.RECEIVING_LOW = 155;
    this.RECEIVING_HIGH = 244;
    this.FOLLOWING_LOW = 245;
    this.FOLLOWING_HIGH = 308;
    this.PRODUCING_LOW = 309;
    this.PRODUCING_HIGH = 400;
  }
}
export const categoryBoundries = new CategoryBoundries();

export const STOP_AND_BREATHE_ONE = 1;
export const STOP_AND_BREATHE_TWO = 2;
