import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"]
})
export class FAQComponent implements OnInit {
  constructor(private router: Router, public route: ActivatedRoute) { }

  ngOnInit() { }

  goHome() {
    this.router.navigate([""]);
  }
}
