import { Injectable } from "@angular/core";
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { OpenResponseComponent } from "../components/open-response/open-response.component";
import { Observable } from "rxjs/Observable";
import { AssessmentService } from "../services/assessment.service";
import { PlatformLocation } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { ContentService } from "../services/content.service";

@Injectable()
export class OpenResponseGuard implements CanDeactivate<OpenResponseComponent> {
  constructor(
    private toast: ToastrService,
    private location: PlatformLocation,
    private content: ContentService
  ) {}

  canDeactivate(
    component: OpenResponseComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    switch (currentRoute.url[0].toString()) {
      case "intro":
        if (nextState.url === "/openresponse/1") {
          return true;
        } else {
          this.showToastError();
          this.location.pushState(null, null, "/openresponse/intro");
          return false;
        }
      case "1":
        if (nextState.url === "/openresponse/2") {
          return true;
        } else {
          this.showToastError();
          this.location.pushState(null, null, "/openresponse/1");
          return false;
        }
      case "2":
        if (nextState.url === "/openresponse/3") {
          return true;
        } else {
          this.showToastError();
          this.location.pushState(null, null, "/openresponse/2");
          return false;
        }
      case "3":
        if (nextState.url === "/openresponse/4") {
          return true;
        } else {
          this.showToastError();
          this.location.pushState(null, null, "/openresponse/3");
          return false;
        }
      case "4":
        if (nextState.url === "/openresponse/ideas") {
          return true;
        } else {
          this.showToastError();
          this.location.pushState(null, null, "/openresponse/4");
          return false;
        }
      case "ideas":
        if (nextState.url === "/openresponse/next") {
          return true;
        } else {
          this.showToastError();
          this.location.pushState(null, null, "/openresponse/ideas");
          return false;
        }
      case "next":
        if (nextState.url === "/wrapup") {
          return true;
        } else {
          this.showToastError();
          this.location.pushState(null, null, "/openresponse/next");
          return false;
        }
      default:
        this.showToastError();
        return false;
    }
  }

  private showToastError() {
    this.content.getContent("wfBackError").subscribe(block => {
      const text = block
        ? block.content
        : "Sorry, you may not go back once you've started.";
      this.toast.error(text, "", {
        timeOut: 3000
      });
    });
  }
}
