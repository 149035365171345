import { Assessment } from "../models/assessment";
import { Content } from "../models/content";
import { DataService } from "./data.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { WfResponse } from "../models/response";
import "rxjs/add/operator/mergeMap";
import "rxjs/add/operator/mergeAll";
import "rxjs/add/operator/map";
import "rxjs/add/observable/of";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/find";
import "rxjs/add/observable/from";
import "rxjs/add/operator/do";
import { encouragementPrefix } from "../shared/constants";

@Injectable()
export class ContentService {
  contentBlocksStream: Observable<Content[]>;
  cachedContentBlocks: Content[];

  constructor(private dataService: DataService) {
    this.contentBlocksStream = this.dataService.GetContentBlocks().do(res => {
      this.cachedContentBlocks = res as Content[];
    });
  }

  public getContent(contentBlockTitle: string): Observable<Content> {
    const res = this.cachedContentBlocks
      ? Observable.of(this.cachedContentBlocks)
      : this.contentBlocksStream;

    return res.map(x => {
      return x.filter(content => content.title === contentBlockTitle)[0];
    });
  }

  public getEncouragements(): Content[] {
    const res: Content[] = this.cachedContentBlocks
      .filter(content => {
        return content.title
          ? content.title.startsWith(encouragementPrefix)
          : false;
      })
      .sort((a: Content, b: Content) => {
        const aSort = parseInt(a.title.replace(/[^\d.]/g, ""), 10);
        const bSort = parseInt(b.title.replace(/[^\d.]/g, ""), 10);
        if (aSort == bSort) {
          return 0;
        }
        if (aSort > bSort) {
          return 1;
        } else {
          return -1;
        }
      });
    return res;
  }
}
