import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs/Observable";

import { CookieService } from "ngx-cookie";
import { DataService } from "../services/data.service";
import { AssessmentService } from "../services/assessment.service";
import { ContentService } from "../services/content.service";
import { Assessment } from "../models/assessment";
import { PreLoaderService } from "../components/preloader/preloader.service";
import { StateService } from "../services/state.service";
import "rxjs/add/operator/toPromise";
@Injectable()
export class OpenForBusinessGuard implements CanActivate {
  constructor(private state: StateService, private dataService: DataService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.dataService.GetConfig().map((config) => {
      this.state.SetOpenForBusiness(config.openforbusiness);
      if (config.openforbusiness === true || next.url.length === 0 || this.state.GetDebugMode()) {
        return true;
      } else {
        this.router.navigate(["/"], { preserveQueryParams: true, preserveFragment: true, queryParamsHandling: "preserve" });
        return false;
      }

    });

  }


}
