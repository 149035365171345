import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import { ContentService } from "../../services/content.service";

@Component({
  selector: "app-fire-content-block",
  templateUrl: "./content.component.html"
})
export class ContentComponent implements OnInit {
  public output: string;
  @Input() id: string;

  constructor(private content: ContentService) { }

  ngOnInit() {
    this.getContent(this.id);
  }

  getContent(id) {
    return this.content.getContent(id).subscribe(x => {
      this.output = x ? x.content : "";
      if (!this.output) {
        console.error("[ERROR] Could not find content block: " + id);
      }
    });
  }
}
