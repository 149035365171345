import { Question } from "../models/question";
import { Injectable } from "@angular/core";
import { Assessment } from "../models/assessment";
import { Score } from "../models/score";
import { DataService } from "./data.service";
import { Observable } from "rxjs/Observable";
import { WfResponse } from "../models/response";
import { Router } from "@angular/router";
import { questionCategory } from "../shared/constants";
import * as _ from "lodash";

@Injectable()
export class AssessmentService {
  private assessment: Assessment;
  private score: Score;

  constructor(private dataService: DataService, private router: Router) { }

  public GetAssessment(): Observable<Assessment> {
    if (this.assessment === undefined) {
      return this.CreateAssessment().do(assessment => {
        this.assessment = assessment;
      });
    } else {
      console.log("GetAssessment in service");
      return Observable.of(this.assessment);
    }
  }

  public GetAssessmentByID(documentid: string): Observable<Assessment> {
    if (this.assessment && this.assessment.documentid === documentid) {
      return Observable.of(this.assessment);
    } else {
      return this.dataService.GetAssessmentByID(documentid).do(assessment => {
        this.assessment = assessment;
        return assessment;
      });
    }
  }

  public UpdateAssessment(assessmentid: string, propertyName: string, value: object) {
    this.assessment[propertyName] = value;
    this.dataService.UpdateAssessment(assessmentid, { [propertyName]: value });
  }

  public SetAssessment(value: Assessment) {
    return this.dataService
      .AddOrUpdateAssessmentWithResponsesAsSubCollection(value)
      .do(assessment => {
        this.assessment = assessment;
      });
  }

  public IsAssessmentSet() {
    return this.assessment != null;
  }

  public GetRequiredResponses(): WfResponse[] {
    if (this.assessment != null) {
      const unanswered = [];
      this.assessment.responses.forEach(response => {
        if (response.answertext == null || response.answertext === undefined) {
          unanswered.push(response);
        }
      });
      console.log(`${this.assessment.responses.length} Total Questions`);
      console.log(`${unanswered.length} Questions Unanswered`);
      return unanswered;
    } else {
      // something went wrong send back to start page
      this.router.navigate([""]);
      return null;
    }
  }

  public GetTotalQuestions(): number {
    return this.assessment.responses.length;
  }

  public SaveResponse(response: WfResponse) {
    this.assessment.responses.forEach((match, index, responses) => {
      if (match.documentid === response.documentid) {
        responses[index] = response;
        // foundIdx = idx;
      }
    });

    this.dataService.SaveResponse(response, this.assessment.documentid);
  }

  // prettier-ignore
  public GetScore(): Score {
    if (this.score !== undefined) {
      return this.score;
    }
    const assessment = this.assessment;
    const score = new Score();
    score.spiritual = this.calcScore(assessment.responses.filter(x => x.category === questionCategory.SPIRITUAL));
    score.financial = this.calcScore(assessment.responses.filter(x => x.category === questionCategory.FINANCIAL));
    score.intellectual = this.calcScore(assessment.responses.filter(x => x.category === questionCategory.INTELLECTUAL));
    score.relational = this.calcScore(assessment.responses.filter(x => x.category === questionCategory.RELATIONAL));
    score.physical = this.calcScore(assessment.responses.filter(x => x.category === questionCategory.PHYSICAL));
    return score;
  }

  public GetAssessmentId(): string {
    if(this.assessment != undefined){
      return this.assessment.documentid;
    }
  }

  public ClearAssessment(): void {
    this.assessment = undefined;
  }

  private calcScore(responses: WfResponse[]): number {
    let score = 0;
    for (const response of responses) {
      score +=
        response.answervalue *
        response.questionweight *
        response.categoryweight;
    }
    return score;
  }

  private CreateAssessment(): Observable<Assessment> {
    // get the questions and add them to the assesment
    return this.dataService
      .GetQuestions()
      .mergeMap(v => Observable.of(this.QuestionsArrayAssessment(v)));
  }

  private QuestionsArrayAssessment(questions: Question[]): Assessment {
    const assessment = new Assessment();
    assessment.responses = new Array<WfResponse>();
    console.log(`${questions.length} questions before ordering`);
    questions = this.orderQuestions(questions);

    questions.forEach((question, i) => {
      if (question.questiontext == null) {
        console.log(`question ${question.questionid} doesn't have questiontext`);
        console.log(JSON.stringify(question));
      }
      const response = new WfResponse(question, i);
      assessment.responses.push(response);
    });

    return assessment;
  }

  private orderQuestions(questions: Question[]): Question[] {
    let sortedQuestions: Question[] = [];
    const groupings = _.sortBy(_.uniqBy(questions, "questiongrouporder").map(x => { return x.questiongrouporder }));

    groupings.forEach(grouping => {
      sortedQuestions = sortedQuestions.concat(_.shuffle(questions.filter(q => q.questiongrouporder == grouping)));
    });

    return sortedQuestions;
  }
}
