import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormArray,
  FormControl,
  FormGroup,
  NgForm,
  Validators
} from "@angular/forms";
import { AssessmentService } from "../../services/assessment.service";

@Component({
  selector: "app-open-response",
  templateUrl: "./open-response.component.html",
  styleUrls: ["./open-response.component.scss"]
})

// Most important part.  [come up with content block name that will work. no text box for answer]
// Any ideas about what a next step could be?   [come up with content block name that will work. no text box for answer]
// What happens next?   [come up with content block name that will work. no text box for answer]
// Then it’s time for Group share.   [come up with content block name that will work. no text box for answer]
export class OpenResponseComponent implements OnInit {
  private openResponse = {
    "/openresponse/intro": {
      content: "wfOpenIntro",
      saveTo: null,
      nextURL: "/openresponse/1"
    },
    "/openresponse/1": {
      content: "wfOpen1Next",
      saveTo: "openResponse1",
      nextURL: "/openresponse/2"
    },
    "/openresponse/2": {
      content: "wfOpen2Next",
      saveTo: "openResponse2",
      nextURL: "/openresponse/3"
    },
    "/openresponse/3": {
      content: "wfOpen3Next",
      saveTo: "openResponse3",
      nextURL: "/openresponse/4"
    },
    "/openresponse/4": {
      content: "wfOpen4Next",
      saveTo: "openResponse4",
      nextURL: "/openresponse/ideas"
    },
    "/openresponse/ideas": {
      content: "wfOpenIdeas",
      saveTo: null,
      nextURL: "/openresponse/next"
    },
    "/openresponse/next": {
      content: "wfOpenNext",
      saveTo: null,
      nextURL: "/wrapup"
    }
  };

  public openResponseForm: FormGroup;
  public isSubmitted = false;
  public currentURL: string;
  public currentPage;

  constructor(
    private router: Router,
    private assessmentService: AssessmentService
  ) {}

  ngOnInit() {
    this.currentURL = this.router.url;
    this.currentPage = this.openResponse[this.currentURL];
    this.openResponseForm = new FormGroup({
      openresponse: new FormControl("", Validators.required)
    });
  }

  doClick() {
    if (this.currentPage.saveTo) {
      // get the users response
      this.isSubmitted = true;
      if (this.openResponseForm.valid) {
        const userResponse = this.openResponseForm.controls["openresponse"]
          .value;
        this.assessmentService.UpdateAssessment(
          this.assessmentService.GetAssessmentId(),
          this.currentPage.saveTo,
          userResponse
        );
        this.router.navigateByUrl(this.currentPage.nextURL);
      }
    } else {
      this.router.navigateByUrl(this.currentPage.nextURL);
    }
  }

  getContentId(): string {
    const contentid: string = this.currentPage.content;
    return contentid;
  }
}
