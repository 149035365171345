import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  NgForm,
  Validators
} from "@angular/forms";
import { AssessmentService } from "../../services/assessment.service";
import { DataService } from "../../services/data.service";
import { ContentComponent } from "../content/content.component";
import { Router } from "@angular/router";
import { AnalyticsService } from "../../services/analytics.service";
import { StateService } from "../../services/state.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-finalquestion-page",
  templateUrl: "./finalquestion-page.component.html"
})
export class FinalquestionPageComponent implements OnInit {
  public learnedForm: FormGroup;
  public isSubmitted = false;
  private sendReflectionUrl: string;

  constructor(
    private assessmentService: AssessmentService,
    private dataService: DataService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private stateService: StateService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.sendReflectionUrl = `https://us-central1-${
      environment.firebaseConfig.projectId
    }.cloudfunctions.net/sendReflectionEmailAttachment`;
    this.learnedForm = new FormGroup({
      learned: new FormControl("", Validators.required)
    });
  }

  public submitForm(theForm: FormGroup) {
    // save the response in our local service
    this.assessmentService.GetAssessment().subscribe(assessment => {
      assessment.completedDate = new Date();
      this.assessmentService.SetAssessment(assessment);

      // submit to firestore
      this.dataService
        .AddOrUpdateAssessmentWithResponsesAsSubCollection(assessment)
        .subscribe(saved => {
          this.sendReflectionScheduleResults(assessment.documentid).subscribe();

          this.analyticsService.trackWayfinderAssessmentCompleted(
            assessment.documentid
          );
          // navigate to the thank you page
          this.router.navigate([this.stateService.GetFinalPage()]);
        });
    });
  }

  private sendReflectionScheduleResults(assessmentId: string) {
    const reqUrl = `${this.sendReflectionUrl}?assessmentid=${assessmentId}`;

    return this.http.get(reqUrl);
  }
}
