// Angular
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { Router } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";

// Third-Party Libraries
import { AngularFireModule } from "angularfire2";
import { AngularFireAuthModule } from "angularfire2/auth";
import { AngularFirestoreModule } from "angularfire2/firestore";
import { ButtonsModule, AccordionModule, TooltipModule } from "ngx-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { CookieModule } from "ngx-cookie";
import {
  Angulartics2Module,
  Angulartics2GoogleTagManager,
  Angulartics2GoogleAnalytics,
  Angulartics2Segment
} from "angulartics2";

// Base
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// Services
import { AnalyticsService } from "./services/analytics.service";
import { AnswersService } from "./services/answer.service";
import { AssessmentService } from "./services/assessment.service";
import { AuthService } from "./services/auth.service";
import { ContentService } from "./services/content.service";
import { CRDSSiteService } from "./services/crossroads.site.service";
import { DataService } from "./services/data.service";
import { PreLoaderService } from "./components/preloader/preloader.service";
import { StateService } from "./services/state.service";

// Pipes
import { SafeHtmlPipe } from "./pipes/safe_html.pipe";

// Guards
import { ResumeOldAssessmentGuard } from "./route-guards/resume-old-assessment.guard";
import { QuestionGuard } from "./route-guards/question.guard";
import { OpenForBusinessGuard } from "./route-guards/openforbusiness.guard";
import { OpenResponseGuard } from "./route-guards/openresponse.guard";
import { StopAndBreatheGuard } from "./route-guards/stopbreathe.guard";

// Components
import { ContentComponent } from "./components/content/content.component";
import { environment } from "../environments/environment";
import { FinalquestionPageComponent } from "./components/finalquestion-page/finalquestion-page.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { HomeComponent } from "./components/home/home.component";
import { InfoPageComponent } from "./components/info-page/info-page.component";
import { InstructionsComponent } from "./components/instructions/instructions.component";
import { PreLoaderComponent } from "./components/preloader/preloader.component";
import { QuestionComponent } from "./components/question/question.component";
import { QuestionFormComponent } from "./components/question-form/question-form.component";
import { QuestionProgressBarComponent } from "./components/question-progress-bar/question-progress-bar.component";
import { QuestionMarkupComposerComponent } from "./components/question-markup-composer/question-markup-composer.component";
import { ResultsComponent } from "./components/results/results.component";
import { ResultsChartComponent } from "./components/results-chart/results-chart.component";
import { ResultsOverviewComponent } from "./components/results-overview/results-overview.component";
import { ResultsOverviewDetailComponent } from "./components/results-overview-detail/results-overview-detail.component";
import { ResumeConfirmationComponent } from "./components/resume-confirmation/resume-confirmation.component";
import { ThankyouComponent } from "./components/thankyou/thankyou.component";
import { StopAndBreatheComponent } from "./components/stop-and-breathe/stop-and-breathe.component";
import { OpenResponseComponent } from "./components/open-response/open-response.component";
import { FAQComponent } from "./components/faq/faq.component";
import { DebounceClickDirective } from "./directives/debounce-click.directive";
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';

export const firebaseConfig = environment.firebaseConfig;

@NgModule({
  declarations: [
    AppComponent,
    ContentComponent,
    FinalquestionPageComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    InfoPageComponent,
    QuestionComponent,
    QuestionFormComponent,
    QuestionMarkupComposerComponent,
    QuestionProgressBarComponent,
    ResultsChartComponent,
    ResultsComponent,
    SafeHtmlPipe,
    PreLoaderComponent,
    ResultsOverviewComponent,
    ResultsOverviewDetailComponent,
    ResumeConfirmationComponent,
    InstructionsComponent,
    ThankyouComponent,
    StopAndBreatheComponent,
    OpenResponseComponent,
    FAQComponent,
    DebounceClickDirective,
    AudioPlayerComponent
  ],
  imports: [
    AccordionModule.forRoot(),
    AngularFireModule.initializeApp(firebaseConfig),
    Angulartics2Module.forRoot([
      Angulartics2GoogleTagManager,
      Angulartics2GoogleAnalytics,
      Angulartics2Segment
    ]),
    AngularFirestoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    TooltipModule.forRoot(),
    CookieModule.forRoot(),
    AngularFireAuthModule,
    HttpClientModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AnalyticsService,
    Angulartics2GoogleAnalytics,
    Angulartics2GoogleAnalytics,
    Angulartics2Segment,
    AnswersService,
    AssessmentService,
    AnswersService,
    AuthService,
    DataService,
    ContentService,
    CRDSSiteService,
    OpenForBusinessGuard,
    OpenResponseGuard,
    PreLoaderService,
    QuestionGuard,
    ResumeOldAssessmentGuard,
    StateService,
    HttpClientModule,
    StopAndBreatheGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
