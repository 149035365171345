export interface IEmbeddedTooltip {
  textBefore: string;
  keywords: string;
  tooltip: string;
}

export class EmbeddedTooltip implements IEmbeddedTooltip {
  textBefore: string;
  keywords: string;
  tooltip: string;

  constructor(textBefore: string, keywords: string, tooltip: string) {
    this.textBefore = textBefore;
    this.keywords = keywords;
    this.tooltip = tooltip;
  }
}
